import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import FAQ from "../components/FAQ"

const ThankyouPage = () => (
  <Layout noMenu={true}>
    <SEO />

    <div className="container">

      <br />
      <br />
      <br />

      <h1 className="title is-1 has-text-centered">
        Dziękujemy za zakup kursu!
      </h1>

    </div>

    <FAQ noPayment={true} noHeader={true} />

  </Layout>
)

export default ThankyouPage
